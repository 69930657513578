var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: "DocumentationModal",
        id: "help-documentation-modal",
        size: "xl",
        "modal-class": "help-documentation-modal mui-animation",
        "hide-header": "",
        "hide-footer": "",
        fade: false,
      },
    },
    [
      _c("div", { staticClass: "help-documentation-modal-content" }, [
        _c("iframe", {
          key: _vm.docSrc,
          ref: "iframe",
          attrs: { width: "100%", height: "100%", src: _vm.docSrc },
          on: { load: _vm.load },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }