var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "ok-variant": "success",
        title: _vm.FormMSG(111, "Comment this zone"),
        "ok-title": _vm.FormMSG(99, "Add Comment"),
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: _vm.handleSubmitModal,
        cancel: _vm.handleCloseModal,
        hidden: _vm.handleCloseModal,
      },
      model: {
        value: _vm.isPinModalOpen,
        callback: function ($$v) {
          _vm.isPinModalOpen = $$v
        },
        expression: "isPinModalOpen",
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-textarea", {
                ref: "pinCommentComposerRef",
                attrs: {
                  autofocus: "",
                  placeholder: _vm.FormMSG(38, "Enter comment..."),
                  rows: "3",
                  required: "",
                },
                model: {
                  value: _vm.commentPayload.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.commentPayload, "message", $$v)
                  },
                  expression: "commentPayload.message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }