<style>
.tip-modal .modal-dialog {
	position: absolute;
	top: 4rem;
	right: 1rem;
	width: 100%;
	max-width: 400px;
	margin: 0;
}
.tip-modal .modal-content {
	border: none;
}
.tip-modal .modal-body {
	border-radius: 0.3rem;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 100% 80%;
	background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 474 418' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Components' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='bg'%3E%3Cpath d='M0 0h474v254.2c-86.7 161-178.7 169.9-276 26.5C100.7 137.3 34.7 72.5 0 86.3V0z' fill='%23F8F5AB' opacity='.3'/%3E%3Cpath d='M0 0h474v188c-74 55.7-144.3 44.9-211-32.3C196.3 78.6 108.7 48 0 63.8V0z' fill='%23C0ED92' opacity='.3' transform='matrix(-1 0 0 1 474 0)'/%3E%3Cpath d='M0 0h474v45.6c-66 128.4-157.7 154-275 77.2C81.7 45.9 15.3 36.2 0 93.8V0z' fill='%2391DEBF' opacity='.3'/%3E%3Cpath d='M91 363c37-7 41-42 41-61s-22-39-41-39-54 40-54 59 17 48 54 41z' fill='%23C0ED92' opacity='.3'/%3E%3Cpath d='M410.8 416.8c11-2.1 12.2-12.5 12.2-18.2 0-5.6-6.5-11.5-12.2-11.5-5.6 0-16 11.8-16 17.5 0 5.6 5 14.2 16 12.2z' fill='%23F8F5AB' opacity='.3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.tip-modal .modal-footer {
	border-top: 0 none transparent;
}
.tip-modal .tip-modal-content strong {
	display: block;
	padding-top: 60px;
	padding-bottom: 24px;
}
</style>
<!--
    title="Tip of the day"
        ok-title="Thank you !"
            :title="this.FormMSG(10, 'Tip of the day')"
-->
<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		:title="this.FormMSG(10, 'Tip of the day')"
		id="tip-of-the-day-modal"
		ref="modal"
		size="xl"
		modal-class="tip-modal mui-animation"
		hide-header
		hide-footer
		:fade="false"
	>
		<div class="tip-modal-content">
			<strong>{{ this.tipOfTheDay.categoryName }}</strong>
			<p v-html="this.tipOfTheDay.description"></p>
			<!-- v-if="loadTipOfTheDay()" -->
			<div class="w-100 py-4">
				<b-button variant="link" @click="nextTip()">
					{{ this.FormMSG(12, 'Next tip') }}
				</b-button>
				<b-button variant="link" @click="openMyTipOfTheDay()">
					{{ this.FormMSG(13, 'All tips') }}
				</b-button>
				<b-button variant="primary" class="float-right" @click="closeTipOfTheDay()">
					{{ this.FormMSG(11, 'Thank you !') }}
				</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

import { GetRandomTipOfDay } from '@/cruds/tipofday.crud';

export default {
	name: 'TipModalComponent',
	mixins: [languageMessages],
	components: {},
	props: {},

	data() {
		return {
			isOpen: false,
			tipOfTheDay: {}
		};
	},

	async mounted() {
		this.tipOfTheDay = await GetRandomTipOfDay(1020);
		//console.log("results for tip of day1:",  this.tipOfTheDay);
		//await this.loadTipOfTheDay();
	},
	methods: {
		async nextTip() {
			this.tipOfTheDay = await GetRandomTipOfDay(1020);
		},
		openMyTipOfTheDay() {
			this.$bvModal.hide('tip-of-the-day-modal');
			this.$router.push('/myTipOfTheDay');
			//alert( " close modal and open the screen my tip of the day");
		},
		closeTipOfTheDay() {
			this.$bvModal.hide('tip-of-the-day-modal');
		}
	}
};
</script>
