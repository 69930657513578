<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		title="DocumentationModal"
		id="help-documentation-modal"
		ref="modal"
		size="xl"
		modal-class="help-documentation-modal mui-animation"
		hide-header
		hide-footer
		:fade="false"
	>
		<div class="help-documentation-modal-content">
			<!--
      <b-row>
          <b-form-group  v-if="newDoc.type == 9">
              <b-button @click="downloadStartUpGuide()" variant="success"  size="md">{{ this.FormMSG(20,'Download the Quick Startup Guide...') }}</b-button>
          </b-form-group>
      </b-row>
    -->
			<iframe ref="iframe" width="100%" height="100%" :src="docSrc" :key="docSrc" @load="load" />
		</div>
	</b-modal>
</template>

<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import { isPwa } from '../../shared/helpers';

export default {
	name: 'HelpModalComponent',
	mixins: [languageMessages],
	data() {
		return {
			isOpen: false,
			docUrl: 'https://gs-doc.netlify.app/',
			docLang: 'en',
			docSlug: '/',
			docSrc: 'https://gs-doc.netlify.app/'
		};
	},
	computed: {
		DocumentationModalTitle() {
			return this.FormMSG(1, 'Help');
		}
	},
	methods: {
		downloadStartUpGuide() {
			let currLang = store.appLanguage();
			let userLevel = store.isProd() ? 2 : store.isAdmin() ? 1 : 0;
			let projectType = store.state.myProject.projectType;
			var urlStr = process.env.VUE_APP_GQL + '/getQuickStartUpGuide/' + currLang + '/' + projectType + '/' + userLevel;
			window.open(urlStr); // in a new window
		},
		load($event) {
			var os = this.mobileOperatingSystem();
			const isIOS = os == 'iOS';
			const iframe = isIOS ? $event.target.contentWindow : $event.target.contentWindow;

			let currLang = store.appLanguage();
			this.docLang = currLang == 1 ? 'fr' : 'en';

			// iframe.location.hash = "your_anchor_name_or_id";
			let newDocSrc = `https://docs.thegreenshot.green/fr-full-solo-film`;

			if (this.docLang === 'fr') {
				newDocSrc = `https://docs.thegreenshot.green/fr-full-solo-film`;
			} else if (this.docLang === 'en') {
				newDocSrc = `https://docs.thegreenshot.green/en-full-film-solo`;
			}

			//this.docSlug = this.$route.path;
			let projectType = store.state.myProject.projectType;
			let sRoute = this.$route.path;

			switch (sRoute.toLowerCase()) {
				case '/mytimesheets':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/timesheets`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/feuilles-dheures`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/timesheets`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/feuilles-dheures`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mes-feuilles-dheures`
					break;
				case '/profile':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/profile`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/profil`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/profile`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/profil`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mon-profil`
					break;
				case '/mycontracts':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/contracts`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/contrats`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/contracts`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/contrats`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mes-contrats`
					break;
				case '/myexpenses':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/expenses`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/frais`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/expenses`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/frais`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mes-frais`
					break;
				case '/myproject':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/projects`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/projets`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/projects`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/projets`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mon-projet`
					break;
				case '/my-dashboard':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/dashboard`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/tableau-de-bord`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/dashboard`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/tableau-de-bord`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mon-tableau-de-bord`
					break;
				case '/timesheet':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/time-cards`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/heures`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/time-cards`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/heures`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mon-travail`
					break;
				case '/mytipoftheday':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/tip-of-the-day`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/astuce-du-jour`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/tip-of-the-day`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/astuce-du-jour`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/mon-astuce-du-jour`
					break;
				case '/loadgreensuppliers':
					newDocSrc = this.getUrlByLang({
						urlEn: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/pc-and-tablet/green-suppliers`,
						urlFr: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/pc-et-tablette/fournisseurs-verts`,
						urlEnMobile: `https://docs.thegreenshot.green/en-full-solo-film/screen-help/smartphone/green-suppliers`,
						urlFrMobile: `https://docs.thegreenshot.green/fr-full-solo-film/aide-ecran/smartphone/fournisseurs-verts`
					});
					// newDocSrc = `https://docs.thegreenshot.green/${this.docLang}-full-solo-film/aide-ecran/fournisseurs-verts`
					break;
				case '/mypo':
				case '/mydocuments':
				case '/loadgreensuppliers':
				case '/mytipoftheday':
				case '/validatets':
				case '/validateexp':
				case '/validatepo':
				case '/contracts':
				case '/inviteusers':
				case '/projectadministration':
					this.docSlug = sRoute.toLowerCase();
					break;
				default:
					this.docSlug = '';
					break;
			}

			this.docSrc = this.docUrl + this.docLang + this.docSlug;
			// console.log("os=",os)
			// console.log("isIOS=",isIOS)

			if (projectType == 3 || projectType == 4) {
				//        console.log("newDocSrc 1:",newDocSrc)
				// window open does not work on iOS
				//window.location.href = newDocSrc

				if (this.$screen.width <= 567) {
					window.open(newDocSrc);
					if (typeof window.open == 'function') {
						window.open(newDocSrc, '_self', '');
					} else {
						// console.log("is not function")
						window.location.href = newDocSrc;
					}
					this.closeDocumentation();
				} else {
					// window.open(newDocSrc)
					if (typeof window.open == 'function') {
						window.open(newDocSrc, '_blank', '');
						// window.open(newDocSrc, '_self', '')
					} else {
						// console.log("is not function")
						window.location.href = newDocSrc;
					}
					this.closeDocumentation();
				}
			} else {
				//     console.log("newDocSrc 2:",newDocSrc)
				//     console.log("currLang=",currLang);
				//     console.log("myProject=",store.state.myProject);
				// let userLevel = store.isProd() ? 2 : store.isAdmin() ? 1 : 0
				// let projectType = store.state.myProject.projectType
				// this.docSrc = process.env.VUE_APP_GQL + '/getQuickStartUpGuide/' + currLang  + '/' + projectType + '/' + userLevel
				// window.open(this.docSrc) // in a new window
				// console.log(this.docSrc);
				iframe.contentWindow.location.href = this.docSrc;
			}
		},

		closeDocumentation() {
			this.openDocumentCount = 0;
			this.$bvModal.hide('help-documentation-modal');
		},

		getUrlByLang({ urlEn, urlFr, urlEnMobile, urlFrMobile }) {
			if (this.$screen.width <= 567) {
				if (this.docLang === 'fr') {
					return urlFrMobile;
				}
				return urlEnMobile;
			} else {
				if (this.docLang === 'fr') {
					return urlFr;
				}
				return urlEn;
			}
		}
	}
};
</script>

<style lang="scss">
.help-documentation-modal {
	.modal-dialog {
		position: fixed;
		top: 55px;
		right: 0;
		bottom: 0;
		width: 100%;
		max-width: 400px;
		margin: 0;
	}
	.modal-body {
		padding: 0;
	}
	.modal-content {
		border: none;
		border-radius: none;
		height: calc(100vh - 55px);
		padding: 0;
		iframe {
			width: 100%;
			height: calc(100vh - 55px);
			border: none;
		}
	}
}
</style>
