var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: this.FormMSG(10, "Tip of the day"),
        id: "wizard-modal",
        size: "xl",
        "modal-class": "wizard-modal mui-animation",
        "hide-header": "",
        "hide-footer": "",
        fade: false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "wizard-modal-content" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c("img", {
                  attrs: {
                    src: "img/brand/thegreenshot-brand.png",
                    width: "150",
                    alt: "TheGreenShot",
                    align: "center",
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c(
                  "p",
                  {
                    staticStyle: { "font-size": "20px", "font-weight": "700" },
                  },
                  [_vm._v(_vm._s(_vm.FormMSG(9, "Welcome to TheGreenShot")))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "list-modal-wizard" }, [
                  _c("ol", [
                    _c("li", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo(
                                "/profile?activeTabContract=true"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(19, "Complete your Deal Memo")
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("/mytimesheets")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(20, "Enter your worked hours")
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("/mytimesheets")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(21, "Share your timesheets")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("/myexpenses")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(25, "Create your expenses")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo(
                                "/myexpenses?activeSecondTab=true"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(26, "Share your expenses")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { "font-weight": "600", "font-size": "13px" } },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.FormMSG(30, "At anytime, click on")) +
                  "\n\t\t\t"
              ),
              _vm.$screen.width > 567 && _vm.lang !== "fr"
                ? _c("img", {
                    staticClass: "navbar-brand-full",
                    attrs: {
                      src: "img/help-img.jpeg",
                      width: "50",
                      alt: "TheGreenShot",
                    },
                  })
                : _vm._e(),
              _vm.$screen.width > 567 && _vm.lang === "fr"
                ? _c("img", {
                    staticClass: "navbar-brand-full",
                    attrs: {
                      src: "img/aide-img.jpeg",
                      width: "50",
                      alt: "TheGreenShot",
                    },
                  })
                : _vm._e(),
              _vm.$screen.width <= 567
                ? _c("img", {
                    staticClass: "navbar-brand-full",
                    attrs: {
                      src: "img/help-img-icon.jpeg",
                      width: "50",
                      alt: "TheGreenShot",
                    },
                  })
                : _vm._e(),
              _vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(31, "to")) + " : "),
              _c("br"),
              _vm._v(
                "\n\t\t\t- " +
                  _vm._s(_vm.FormMSG(32, "Reopen this wizard")) +
                  " "
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t- " +
                  _vm._s(_vm.FormMSG(33, "See online help for such screen")) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "checkbox-1", name: "checkbox-1" },
                      on: { change: _vm.updateShowWizard },
                      model: {
                        value: _vm.valueShowWizard,
                        callback: function ($$v) {
                          _vm.valueShowWizard = $$v
                        },
                        expression: "valueShowWizard",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              34,
                              "Do not display this wizard when starting the application"
                            )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.closeWizardModal()
                },
              },
            },
            [_vm._v("\n\t\t\t" + _vm._s(this.FormMSG(12, "Close")) + "\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }